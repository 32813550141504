@require '/src/dev'

/*


        # grid--cols{f}to{t}

        Grid system for dynamic content. This allows any amount of units
        to be spread dynamicly on line(s), from {f} on each line on mobile
        to {t} on each line on larger screens

        {f}
        1, 2, 3

        {t}
		2, 3, 4, 5, 6, 7



*/

.grid--cols1to2 > .grid__unit,
.grid--cols1to3 > .grid__unit,
.grid--cols1to4 > .grid__unit
    width: $1of1

.grid--cols2to3 > .grid__unit,
.grid--cols2to4 > .grid__unit,
.grid--cols2to5 > .grid__unit,
.grid--cols2to6 > .grid__unit
    width: $1of2

.grid--cols3to4 > .grid__unit,
.grid--cols3to6 > .grid__unit,
.grid--cols3to7 > .grid__unit
    width: $1of3

+bp(max480)
    .grid--cols2to6 > .grid__unit:nth-child(2n + 1),
    .grid--cols2to5 > .grid__unit:nth-child(2n + 1)
        clear: both


+bp(min480 max768)
    .grid--cols2to6 > .grid__unit:nth-child(3n + 1),
    .grid--cols2to5 > .grid__unit:nth-child(3n + 1),
    .grid--cols1to4 > .grid__unit:nth-child(2n + 1),
    .grid--cols1to3 > .grid__unit:nth-child(2n + 1)
        clear: both


+bp(min480)
    .grid--cols1to3 > .grid__unit,
    .grid--cols1to4 > .grid__unit
        width: $1of2

    .grid--cols2to5 > .grid__unit,
    .grid--cols2to6 > .grid__unit
        width: $1of3


+bp(min768)
    .grid--cols1to2 > .grid__unit
        width: $1of2

    .grid--cols1to3 > .grid__unit,
    .grid--cols2to3 > .grid__unit
        width: $1of3

    .grid--cols1to4 > .grid__unit,
    .grid--cols2to4 > .grid__unit,
    .grid--cols3to4 > .grid__unit
        width: $1of4

    .grid--cols2to5 > .grid__unit
        width: $1of5

    .grid--cols2to6 > .grid__unit
    .grid--cols3to6 > .grid__unit
        width: $1of6

    .grid--cols3to7 > .grid__unit
        width: $1of7

    .grid--cols1to2 > .grid__unit:nth-child(2n + 1),
    .grid--cols1to3 > .grid__unit:nth-child(3n + 1),
    .grid--cols2to3 > .grid__unit:nth-child(3n + 1),
    .grid--cols1to4 > .grid__unit:nth-child(4n + 1),
    .grid--cols2to4 > .grid__unit:nth-child(4n + 1),
    .grid--cols3to4 > .grid__unit:nth-child(4n + 1),
    .grid--cols2to5 > .grid__unit:nth-child(5n + 1),
    .grid--cols2to6 > .grid__unit:nth-child(6n + 1)
        clear: both


+bp(max768)
    .grid--cols3to4 > .grid__unit:nth-child(3n + 1),
    .grid--cols2to4 > .grid__unit:nth-child(2n + 1),
    .grid--cols2to3 > .grid__unit:nth-child(2n + 1)
        clear: both
